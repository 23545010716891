import "../../../../../node_modules/react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchTransactionList } from "../../../../Redux/Actions/fetchTransactionList";
import DataTable from "../../../Layouts/Data-Table/DataTable";
import download from "../../../../Assets/Images/download.svg";
import merge from "../../../../Assets/Images/Vector.svg";
import "./PolicyCard.css";
import { exportPDF, getBase64, exportPdfPageOne, exportPdfPageTwo, base64ToUint8Array } from "../../../../utils/commonUtils";
import { exportInvoicePDF } from "../../../../utils/invoicePdf";
import edit from "../../../../Assets/Images/edit-table.svg";
import pdfToText from 'react-pdftotext';
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../Layouts/Loader/Loader";
import { PDFDocument } from 'pdf-lib';
import { Buffer } from "buffer";
import Modal from "react-modal";
import InputFields from "../../../InputField/TextField/InputField";
import Button from "../../../InputField/Button/Button";
import { decryptPdfAction } from "../../../../Redux/Actions/decryptpdfAction";
import { sendEmail } from "../../../../Redux/Actions/SendEmail";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const NoPoliciesFound = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timeoutRef = useRef(null);
  let policyTypeList = [
    { value: "Comprehensive", label: "Comprehensive" },
    { value: "Third Party", label: "Third Party" },
    { value: "Bundled (3Y TP + 1Y OD)", label: "Bundled" },
  ];

  const handleHomePage = () => {
    navigate("/View-Plans");
  };

  // userToken
  const userToken = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.loginData?.token;
  });
  let getTransactionListTotalCount = useSelector((state) => {
    return state?.getTransactionList?.data?.data;
  });


  let getTransactionList = useSelector((state) => {
    return state?.getTransactionList?.data?.data?.transaction;
  });

  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [newLoder, setNewLoadi] = useState(false);
  const [errors, setErrors] = useState({});
  const [TransactionData, setTransactionData] = useState(null);
  const [ShowValidateBtn, setShowValidateBtn] = useState(true);
  const [filterText, setFilterText] = React.useState("");
  const [serachQuery, setSerachQuery] = React.useState("")
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [serchQueryFlg,setSeachQueryFlag]=useState(false)
  const [page, setPage] = React.useState(0);
  const [FormData, setFormData] = useState({
    netPremium: null,
    gst: null,
    totalPremium: null,
  })

  async function getTransactionListData() {
    let bodyData = {
      userToken: userToken,
      pageNumber: page + 1,
      serachValue: serachQuery,
      pagelimit: rowsPerPage
    }
    await dispatch(fetchTransactionList(bodyData));

  }
console.log(serachQuery,"serachQueryserachQuery");

  useEffect(() => {
    setNewLoadi(true)
    getTransactionListData().then((data) => {
      setNewLoadi(false)
    }).catch((err) => {
      setLoading(false)
    })
  }, [page,serchQueryFlg ]);


  useEffect(() => {
    if (FormData.netPremium !== null, FormData.gst !== null, FormData.totalPremium !== null) {
      setShowValidateBtn(false)
    }
  }, [FormData])

  function openModal(data) {
    setIsOpen(true);
    setTransactionData(data);
    setErrors({})
  }

  function closeModal() {
    setIsOpen(false);
    setFormData((prevItems) => ({
      ...prevItems,
      netPremium: null,
      gst: null,
      totalPremium: null,
    }))
  }

  const validatePremiumDetails = () => {
    const newErrors = {};
    if (!FormData.gst) {
      newErrors.gst = "GST is required";
    }
    if (!FormData.totalPremium) {
      newErrors.totalPremium = "Total Premium is required";
    }
    if (!FormData.netPremium) {
      newErrors.netPremium = "Net Premium is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Function to extract value based on pattern
  function extractValue(text, pattern, matchNo = 1) {
    const match = pattern.exec(text);
    return match ? (matchNo == 1 ? match[1] : match[2]) : null;
  }

  const extractText = async (event, data) => {

    // alert("kakak")

    console.log(data?.proposal_body?.chassisNumber, "datttttta");

    setLoading(true);
    const file = event.target.files[0]
    setShowValidateBtn(true);
    closeModal();
    let packagePremium = parseFloat(data?.netPremium) ? parseFloat(data?.netPremium) : "0";
    let igst = parseFloat(data?.gst) ? parseFloat(data?.gst) : "0";
    let totalPremium = parseFloat(data?.totalPremium) ? parseFloat(data?.totalPremium) : "0";
    // pdfToText(file)
    //   .then(async (text) => {

    let companyName = data?.proposal_body?.companyName ? data?.proposal_body?.companyName : "ACKO"
    // let regexTotalPremium = '';
    // let regexSGST = '';
    // let regexCGST = '';
    // let regexPackage = '';

    // switch (companyName) {
    //   case "ACKO":
    //     regexTotalPremium = /Total Premium\s+\s+([\d,.]+)/;
    //     regexSGST = /IGST\s+\(18%\)\s+([\d,.]+)/;
    //     regexCGST = /NOTAVAILABLE/;
    //     regexPackage = /Total Package Premium \(A\+B\)      ([0-9,]+)\.00 IGST/;
    //     break;
    //   case "UNITED":
    //     regexTotalPremium = /TOTAL PAYABLE PREMIUM\s*([\d.,]+)/;
    //     regexSGST = /IGST\(18%\)\s*([\d.,]+)/;
    //     regexCGST = /NOTAVAILABLE/;
    //     regexPackage = /Premium\(A\+B\)\s*([\d.,]+)/;
    //     break;
    //   case "TATA":
    //     regexPackage = /Net Premium \(A\+B\+C\) \s* ₹ \s*([\d,]+\.\d{2})/;
    //     regexSGST = /UGST\/SGST @9 %\s+₹\s+([^ ]+)/;
    //     regexCGST = /CGST @9 %\s+₹\s+([^ ]+)/;
    //     regexTotalPremium = /Total Policy Premium \s*([\d,]+\.\d{2})/;
    //     break;
    //   case "BAJAJ":
    //     regexPackage = /Total Premium \(Net Premium\) \(A\+B\)\s*([\d,]+\.\d{2})/;
    //     regexSGST = /State GST \(9%\)\s*([\d,]+\.\d{2})/;
    //     regexCGST = /Central GST \(9%\)\s*([\d,]+\.\d{2})/;
    //     regexTotalPremium = /Final Premium \( Rupees (.*) Only \)\s*([\d,]+\.\d{2})/;
    //     break;
    //   default:
    //     regexTotalPremium = /Total Premium\s+\s+([\d,.]+)/;
    //     regexSGST = /IGST\s+\(18%\)\s+([\d,.]+)/;
    //     regexCGST = /NOTAVAILABLE/;
    //     regexPackage = /Total Package Premium \(A\+B\)      ([0-9,]+)\.00 IGST/;
    //     break;
    // }

    // let packagePremium = extractValue(text, regexPackage);
    // let igst = extractValue(text, regexSGST);
    // let cgst = ((companyName == "TATA" || companyName == "BAJAJ")
    //   ? extractValue(text, regexCGST) : "0") //extractValue(text, regexCGST); //if tata and bajaj
    // let totalPremium = extractValue(text, regexTotalPremium, companyName == "BAJAJ" ? 2 : 1); // for bajaj

    // // Output the extracted values
    // packagePremium = parseFloat(packagePremium.split(",").join(""));
    // igst = parseFloat(igst.split(",").join(""));
    // cgst = ((companyName == "TATA" || companyName == "BAJAJ")
    //   ? parseFloat(cgst.split(",").join("")) : 0); // if tata and bajaj
    // totalPremium = parseFloat(totalPremium.split(",").join(""))

    //if tata and bajaj
    // igst = igst + cgst

    if (packagePremium && igst && totalPremium) {
      // pdf generate single page  with acko premium
      let invoicePdfb64 = await exportInvoicePDF(data, { packagePremium, igst, totalPremium }, FormData);

      // merge 2 page pdf brilljust last pages
      let pageOne = await exportPdfPageOne({ ...data.proposal_body, ... { is_opt_gst: data.is_opt_gst } }, data.certificate_no, true);
      let pageTwo = await exportPdfPageTwo({ ...data.proposal_body, ... { is_opt_gst: data.is_opt_gst } }, data.certificate_no, true);

      let inputfile = await getBase64(file);

      if (companyName != "ACKO") {
        //decrypt pdf file
        let dispatchPdfDecryot = await dispatch(
          decryptPdfAction({
            data: {
              "pdfBase64": inputfile
            }
          })
        );
        if (dispatchPdfDecryot?.payload?.status === 1) {
          inputfile = dispatchPdfDecryot.payload.data;
        }
      }

      let pdfBase64Strings = []

      //merge all 3 pdf
      pdfBase64Strings = [
        inputfile,
        pageOne,
        // last2pagepdf
      ];

      if (!data.is_opt_gst) {
        pdfBase64Strings.push(invoicePdfb64)
      }

      if (!data?.is_hide_master_policy) {
        pdfBase64Strings.push(pageTwo)
      }

      const mergedPdf = await PDFDocument.create();

      for (const base64String of pdfBase64Strings) {
        const pdfBytes = base64ToUint8Array(base64String);
        const pdf = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      const mergedPdfBytes = await mergedPdf.save();
      var b64 = Buffer.from(mergedPdfBytes).toString('base64');

      const linkSource = `data:application/pdf;base64,${b64}`;
      const downloadLink = document.createElement("a");
      const fileName = "MERGE_" + data.certificate_no.replace('/', "_") + '.pdf';

      let payload = {
        "data": {
          "fileName": fileName,
          "chassisNumber": data?.proposal_body?.chassisNumber,
          "customerName": data?.first_name + " " + data?.last_name,
          "policyNumber": data.certificate_no,
          "email": data?.proposal_body?.emailId,
          "base64PdfData": b64,
        }
      }

      dispatch(sendEmail(payload));

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      closeModal()
      setLoading(false);
    } else {
      closeModal()
      setLoading(false);
      toast.error("Invalid PDF !",
        {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      )
    }
    // })
    // .catch((error) => {
    //   setLoading(false);
    //   toast.error("Invalid PDF !",
    //     {
    //       position: "top-center",
    //       autoClose: 1500,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //     }
    //   )
    // })
  }

  const columns = [
    { id: "product", label: "Product" },
    { id: "policyNumber", label: "Policy Number" },
    { id: "insured", label: "Insured" },
    { id: "Date", label: "Date" },
    { id: "Amount", label: "Amount" },
    { id: "mobileNo", label: "Mobile No" },
    { id: "actions", label: "Actions" },
  ];

  // const data = getTransactionList

  const dataList = getTransactionList?.map((data) => ({
    product: (
      <div className="product_container">
        <p>{data.product_name}</p>
      </div>
    ),
    policyNumber: data.certificate_no,
    insured: data.first_name + " " + data.last_name,
    Date: data.created_date,
    Amount: data.amount,
    mobileNo: data.mobile,
    actions: (
      <div className="tableBtn-container">
        <button
          className="downloadBtn-PolicyList"
          onClick={async (e) => {
            setLoading(true);
            await exportPDF({ ...data.proposal_body, ... { is_opt_gst: data.is_opt_gst, is_hide_master_policy: data.is_hide_master_policy } }, data.certificate_no)
            e.target.value = null
            setLoading(false);
          }
          }
        >
          <img src={download} alt="" srcSet="" />
        </button>

        <div className="custom-upload">
          {data.is_opt_gst ?
            <>
              <input type="file" className="inputFile" id="inputFileMerge" onChange={(e) => {
                extractText(e, data)
                e.target.value = null
              }} />
              <label htmlFor="inputFileMerge" className="customUploadBtn">
                <button className="uploadBtn-PolicyList ">
                  <img src={merge} alt="" srcSet="" />
                </button>
              </label>
            </>
            : <>
              <button className="uploadBtn-PolicyList " onClick={(e) => openModal(data)} >
                <img src={merge} alt="" srcSet="" />
              </button>
            </>
          }
        </div>

      </div>
    ),
  }));
console.log(dataList,"dataListdataListdataList");

  const HanldeFormData = (e, constantName) => {
    const { name, value, checked } = e?.target || {};
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="popUp-field-container">
          <h3>Enter Premium Details</h3>
          <div className="input-list-editVehicleDetails">
            <div className="input-container-form">
              <InputFields
                type="number"
                placeholder="Net Premium"
                name="netPremium"
                className="vehicleNumber"
                onChangeEvent={HanldeFormData}
                value={FormData.netPremium}
                errState={errors?.netPremium ? true : false}
                errMsg={errors?.netPremium}
                errClassName="input-err"
              />
              <InputFields
                type="number"
                placeholder="GST"
                name="gst"
                className="vehicleNumber"
                onChangeEvent={HanldeFormData}
                value={FormData.gst}
                errState={errors?.gst ? true : false}
                errMsg={errors?.gst}
                errClassName="input-err"
              />
            </div>
            <div className="input-container-form">
              <InputFields
                type="number"
                placeholder="Total Premium"
                name="totalPremium"
                className="vehicleNumber"
                onChangeEvent={HanldeFormData}
                value={FormData.totalPremium}
                errState={errors?.totalPremium ? true : false}
                errMsg={errors?.totalPremium}
                errClassName="input-err"
              />
              <div></div>
            </div>

            <div className="btngrp-container">
              <Button
                text="Back"
                className="secondry_btn btn-vehicledetails "
                event={closeModal}
              />
              {
                ShowValidateBtn ? (
                  <div className="custom-upload">
                    <button className="button btn-vehicledetails " onClick={validatePremiumDetails} >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="custom-upload">
                    <input type="file" className="inputFile" id="inputFileMerge" onChange={(e) => {
                      extractText(e, { ...TransactionData, ...FormData })
                      e.target.value = null
                    }} />
                    <label htmlFor="inputFileMerge" className="customUploadBtn">
                      <button className="button btn-vehicledetails ">
                        Save
                      </button>
                    </label>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </Modal>

      {loading ? <Loader /> : null}

      <DataTable columns={columns} data={dataList} getTransactionListTotalCount={getTransactionListTotalCount} page={page} setPage={setPage} filterText={filterText} setFilterText={setFilterText} serachQuery={serachQuery} setSerachQuery={setSerachQuery} timeoutRef={timeoutRef} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} newLoder={newLoder} setNewLoadi={setNewLoadi} setSeachQueryFlag={setSeachQueryFlag} />
      <ToastContainer />
    </>
  );
};

export default NoPoliciesFound;
